<swiper-container
  autoplay-delay="5000"
  autoplay-pause-on-mouse-enter="true"
  [breakpoints]="breakpoints"
  class="slider-swiper"
>
  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/rolo/pinpoint'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/ambienta-banner-mobile-1.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/ambienta-banner-1.webp'
          }}"
          alt="Confira nossas promoções"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>
</swiper-container>
